<template>
  <div class="container">
    <div class="row">
      <Notif :notif="notif" />
    </div>
    <div class="col-md-4 mx-auto mt-4">
      <div class="card">
        <div class="card-header header-blue pb-2">
          <h4 class="text-center my-auto">NOUVEAU MOT DE PASSE</h4>
        </div>
        <div class="card-body">
          <form>
            <template>
              <div class="input-group mb-2">
                <div class="input-group-prepend">
                  <span class="input-group-text"
                        id="basic-addon1">
                    &#128274;
                  </span>
                </div>
                <input class="form-control "
                       type="password"
                       placeholder="nouveau mot de passe"
                       v-model="form.mot_de_passe"
                       name="mot_de_passe" />
              </div>
              <span v-if="form.errors().has('mot_de_passe')"
                    v-text="form.errors().get('mot_de_passe')"
                    class="errorMsg mb-3"> </span>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"
                        id="basic-addon1">
                    &#128274;
                  </span>
                </div>
                <input class="form-control "
                       type="password"
                       placeholder="Confirmer mot de passe"
                       v-model="form.mot_de_passe_confirmation"
                       name="mot_de_passe_confirmation" 
                       @keyup.enter="submit"/>
              </div>
              <span v-if="form.errors().has('mot_de_passe_confirmation')"
                    v-text="form.errors().get('mot_de_passe_confirmation')"
                    class="errorMsg mb-3"> </span>
              <div class="btn_submit">
                <input type="button"
                       value="ENVOYER"
                       class="btn btn-blue"
                       :disabled="form.empty()"
                       @click="submit" />
              </div>
              <div class="bottom_dec">
                <p>
                  <a href="../../"
                     @click="backing()"
                     replace>Retour </a>
                </p>
              </div>
            </template>
          </form>
          <!-- <p class="errormsg">{{ forgotmsg }}</p>
          <p class="errormsg">{{ errors }}</p> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import form from "vuejs-form"
import { mapGetters, mapActions, mapMutations  } from "vuex"
import Notif from "@/components/shared/Toast"
export default {
  name: "Resetmsg",
  components: {
    Notif
  },
  data: () => ({
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    form: form({
      mot_de_passe: "",
      mot_de_passe_confirmation: ""
    })
      .rules({
        mot_de_passe: "required|min:5|confirmed",
        mot_de_passe_confirmation: "required|min:5"
      })
      .messages({
        "mot_de_passe.mot_de_passe": "Required field with minmun 5 length.",
        "mot_de_passe_confirmation.mot_de_passe_confirmation": "Password confirmation does not match"
      })
  }),
  created() {
    console.log(this.form.data)
    localStorage.setItem("token", this.$route.params.token)
  },
  watch: {
    ["form.data"]: {
      deep: true,
      immediate: false,
      handler: "onFormChange"
    },
    resetmsg() {
      if (this.resetmsg) {
        this.notif.message = this.resetmsg
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            localStorage.removeItem("user")
            localStorage.removeItem("userauth")
            localStorage.removeItem("token")
            this.resetMsg("")
            this.$router.push({ name: "Login" })
          }.bind(this),
          3000
        )
      }
    },
    errors() {
      if (this.errors) {
        this.notif.message = this.errors
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setErors("")
          }.bind(this),
          3000
        )
      }
    }
  },
  computed: {
    ...mapGetters(["resetmsg", "errors"])
  },
  methods: {
    ...mapActions(["passwordReset"]),
    ...mapMutations(["resetMsg","setErors"]),
    backing() {
      localStorage.removeItem("user")
      localStorage.removeItem("userauth")
      localStorage.removeItem("token")
    },
    submit() {
      this.form.validate()
      if (
        !this.form
          .validate()
          .errors()
          .any()
      ) {
        this.passwordReset({ id: this.$route.params.id, password: this.form.data })
      }
    }
  }
}
</script>
